import React, { useEffect, useState } from "react";
import API from "./../../../http/api";
import parse from 'html-react-parser';
import { formatImage } from "../../../util/funcao";
export default function ProximasCorridasPiloto({ pilotos }) {
  
  const [ proximosJogos, setProximosJogos ] = useState([])

  const proximasOdds = () => {
    try {
      if(pilotos !== "" && pilotos.length > 0){
        API.post(`proxima-corrida-pilotos`, {
          'pilotos'  : pilotos
        }).then(async (res) => {
          setProximosJogos(res.data.proximosJogos)
        });
      }
    } catch (e) {}
  };


  useEffect(() => {
    proximasOdds()
  }, [pilotos]);

  useEffect(() => {
    proximasOdds()

    const ajaxTime = setInterval(() => {
      proximasOdds()
    }, 15000);

    return (_) => clearTimeout(ajaxTime);
  }, []);

  
  return (
    <>
      {proximosJogos !== undefined && proximosJogos !== '' && proximosJogos.length > 0 && (
        <div className="row row-sm">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <p style={{ fontSize: '14px', textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold', lineHeight: '30px'}}>
                  Próximas Corridas
                  <br />
                  <div>
                    {proximosJogos.map((item, index) => (
                      <div key={index} >
                        <h3>{item.corrida}</h3>
                        {item.odds.map((val, key) => (
                            <span key={key} style={{ marginRight: 10}}>
                              {parse(formatImage(val.piloto))}
                              { val.odd }
                            </span>
                        ))}
                        
                      </div>
                    ))}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
